// https://bugs.chromium.org/p/chromium/issues/detail?id=872770 Task: https://jira.hilti.com/browse/BUDQBP-20827
/* eslint-disable  @typescript-eslint/no-explicit-any, prefer-rest-params  */
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  (Node.prototype.removeChild as any) = function (this: any, child: any) {
    if (child.parentNode !== this) {
      if (console) {
        console.error('Cannot remove a child from a different parent', child, this);
      }
      return child;
    }
    return originalRemoveChild.apply(this, arguments as any);
  }

  const originalInsertBefore = Node.prototype.insertBefore;
  (Node.prototype.insertBefore as any) = function (this: any, newNode: any, referenceNode: any) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
      }
      return newNode;
    }
    return originalInsertBefore.apply(this, arguments as any);
  }
}
/* eslint-enable  @typescript-eslint/no-explicit-any, prefer-rest-params */